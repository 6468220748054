<template>
    <div class="container">
        <el-form :model="dataForm" ref="dataForm" label-position="top">
            <el-form-item>
                <el-switch
                    v-model="dataForm.enable"
                    active-text="开启分销功能"
                    inactive-text="关闭分销功能"
                    :active-value="true"
                    :inactive-value="false">
                </el-switch>
            </el-form-item>
            <template v-if="dataForm.enable">
                <el-form-item label="分销层级">
                    <el-radio-group v-model="dataForm.level">
                        <el-radio :label="2">二级分销</el-radio>
                        <el-radio :label="3">三级分销</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item v-if="dataForm.level > 1" label="一级分佣(元)">
                    <el-input type="number" v-model="dataForm.first" placeholder="一级分佣金额，单元：元" clearable/>
                </el-form-item>
                <el-form-item v-if="dataForm.level > 2" label="二级分佣(元)">
                    <el-input type="number" v-model="dataForm.second" placeholder="二级分佣金额，单元：元" clearable/>
                </el-form-item>
            </template>
            <el-form-item>
                <el-button v-if="$perm('league_PlatformDistribution_edit')" @click="doSave" :loading="loading" type="primary">保 存
                    配 置
                </el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
export default {
    name: "Distribution",
    data: function () {
        return {
            loading: false,
            dataForm: {
                enable: true,
                level: 3,
                first: 50,
                second: 9
            }
        }
    },
    activated: function () {
        this.getConfig()
    },
    methods: {
        getConfig: function () {
            this.$helper._get(this, 'league.DistributionSetting.config', {}, resp => {
                this.dataForm = resp.data
            })
        },
        doSave: function () {
            // 数据校验: 二级时一级佣金必填, 三级时一级和二级佣金必填
            if (this.dataForm.enable && this.dataForm.level > 1 && (isNaN(Number(this.dataForm.first)) || Number(this.dataForm.first) < 0)) return this.$message.warning("请正确输入一级佣金~")
            if (this.dataForm.enable && this.dataForm.level > 2 && (isNaN(Number(this.dataForm.second)) || Number(this.dataForm.second) < 0)) return this.$message.warning("请输入二级佣金~")
            this.$helper._post(this, 'league.DistributionSetting.update', {setting: JSON.stringify(this.dataForm)}, resp => {
                this.$message.success(resp.message)
            })
        }
    }
}
</script>

<style scoped>

</style>
